import { ApolloProvider } from '@apollo/client';
import { ResetCSS } from 'common/assets/css/style';
import { lightTheme } from 'common/theme/light/lightTheme';
import { GlobalStyle } from 'containers/app.style';
import { apolloClient } from 'graphql/client';
import React, { FC, PropsWithChildren } from 'react';
import { ThemeProvider } from 'styled-components';
import WalletProvider from 'containers/WalletProvider/WalletProvider';

const Contexts: FC<PropsWithChildren> = ({ children }) => {
  const theme = lightTheme;
  return (
    <ThemeProvider theme={theme}>
      <WalletProvider>
        <ApolloProvider client={apolloClient}>
          <ResetCSS />
          <GlobalStyle />
          {children}
        </ApolloProvider>
      </WalletProvider>
    </ThemeProvider>
  );
};

export default Contexts;
